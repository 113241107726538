<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
import brandMixin from '@/mixins/brand.js'

export default {
    name: "GoogleTrueRating",
    extends: Line,
    mixins: [reactiveProp, brandMixin],
    props: ['chartData'],
    data: () => ({
        type: 'line',
    }),
    computed: {
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                plugins: {
                    datalabels: {
                        display: true
                    }
                },
                layout: {
                    padding: {
                        left: 20,
                        right: 20,
                        top: 25,
                        bottom: 10
                    }
                },
                showTooltips: false,
                clip: { left: 15, top: false, right: -2, bottom: 0 },
                scales: {
                    clip: { left: 15, top: false, right: -2, bottom: 0 },
                    yAxes: [
                        {
                            type: "linear",
                            position: "left",
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 5,
                                maxTicksLimit: 6
                            },
                            id: "y-axis-1",
                        },
                    ],
                    scaleLabel: {
                        fontSize: 14
                    },
                },
            }
        }
    },
    watch: {
        chartData() {
            this.renderChart(this.chartData, this.options)
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.options)
    }
}
</script>