<template lang="pug">
div
    v-col(md="12").contentOutPadding.tablaGR
        v-data-table#tableComments(
            :headers="headers"
            :items="items"
            hide-default-footer
            :page.sync="pageCustom"
            :items-per-page="itemsPerPageCustom"
            item-key="idGenerate"
            :loading="loading"
            :loading-text="loadingtext"
            @page-count="pageCount = $event"
        )
            template(v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }")
                span.d-flex.items-center(v-if="header.value === 'name'")
                    span {{ header.text }}
                    .boxOrder(v-if="renderOrderingArrow")
                        sort(:orderBy="'center__name'" :orderByActual="ordering" @sort="setOrder($event)")
                span.d-flex.items-center(v-else-if="header.value === 'brand'")
                    span {{ header.text }}
                    .boxOrder(v-if="renderOrderingArrow")
                        sort(:orderBy="'brand'" :orderByActual="ordering" @sort="setOrder($event)")
                span.d-flex.items-center(v-else-if="header.value === 'n_reviews'")
                    span {{ header.text }}
                    .boxOrder(v-if="renderOrderingArrow")
                        sort(:orderBy="'n_reviews'" :orderByActual="ordering" @sort="setOrder($event)")
                span.d-flex.items-center(v-else-if="header.value === 'historical_rating'")
                    span {{ header.text }}
                    .boxOrder(v-if="renderOrderingArrow")
                        sort(:orderBy="'historical_rating'" :orderByActual="ordering" @sort="setOrder($event)")
                span.d-flex.items-center(v-else-if="header.value === 'trends.real_rating'")
                    span {{ header.text }}
                    .boxOrder(v-if="renderOrderingArrow")
                        sort(:orderBy="'real_rating'" :orderByActual="ordering" @sort="setOrder($event)")
                span.d-flex.items-center(v-else-if="header.value === 'trends.n_5_stars_for_next_near_decimal'")
                    span {{ header.text }}
                    .boxOrder(v-if="renderOrderingArrow")
                        sort(:orderBy="'n_5_stars_for_next_near_decimal'" :orderByActual="ordering" @sort="setOrder($event)")
                span.d-flex.items-center(v-else-if="header.value === 'trends.percentage_of_progress'")
                    span {{ header.text }}
                    .boxOrder(v-if="renderOrderingArrow")
                        sort(:orderBy="'percentage_of_progress'" :orderByActual="ordering" @sort="setOrder($event)")
                span.d-flex.items-center(v-else-if="header.value === 'real_rating_tendency'")
                    span {{ header.text }}
                    .boxOrder(v-if="renderOrderingArrow")
                        sort(:orderBy="'real_rating_tendency'" :orderByActual="ordering" @sort="setOrder($event)")
                span(v-else) {{ header.text }}

            template(v-slot:item.id="{index}")
                span.font-weight-bold {{ index + 1 }}
            template(v-slot:item.name="{ item }")
                router-link.btnCentro.link-text(:to="goItemNextLevelCustom(item)") {{ item.center_name }}
            template(v-slot:item.trends.real_rating="{ item }")
                span.font-weight-bold(v-if="item.real_rating") {{ item.real_rating }}
                span(v-else) -
            template(v-slot:item.trends.n_5_stars_for_next_near_decimal="{ item }")
                div.d-flex.justify-center.items-center(v-if="item.n_5_stars_for_next_near_decimal && item.next_google_rating")
                    span.font-weight-bold.pr-1 {{ item.n_5_stars_for_next_near_decimal }}
                    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="5.00806" y1="5.30095" x2="7.30095" y2="3.00806" stroke="#222226" stroke-linecap="round"/>
                        <line x1="7.30095" y1="3.00806" x2="5.00806" y2="0.715163" stroke="#222226" stroke-linecap="round"/>
                        <path d="M8 3.00806H1" stroke="#222226" stroke-linecap="round"/>
                    </svg>
                    span.text-13.pl-1 {{ item.next_google_rating }}
                span(v-else) -
            template(v-slot:item.trends.percentage_of_progress="{ item }")
                span(v-if="item.percentage_of_progress") {{ item.percentage_of_progress.toFixed(0) }}%
                span(v-else) -
            template(v-slot:item.real_rating_tendency="{ item }")
                span(v-if="item.real_rating_tendency") {{ item.real_rating_tendency.toFixed(3) }}%
                span(v-else) -
    
</template>

<script>
import Sort from "@/components/common/Sort.vue"
import commonMixin from "@/mixins/common";

export default {
    mixins: [commonMixin],
    components: { Sort },
    props: {
        loading: {
            type: Boolean,
            default: true,
            required: true,
        },
        items: {
            type: [Array],
            default: null,
        }
    },
    data() {
        return {
            headers: [
                {
                    text: "#",
                    align: "start",
                    sortable: false,
                    value: "id",
                },
                {
                    text: "Nombre",
                    align: "start",
                    sortable: false,
                    value: "name",
                    width: "15%"
                },
                { text: "Marca", value: "brand", sortable: false, width: "15%" },
                { text: "N reseñas", value: "n_reviews", sortable: false, align: "center" },
                { text: "Rating google", value: "historical_rating", sortable: false, align: "center" },
                { text: "Rating real", value: "trends.real_rating", sortable: false, align: "center" },
                { text: "N reseñas necesarias", align: "center", value: "trends.n_5_stars_for_next_near_decimal", sortable: false },
                { text: "Progreso", value: "trends.percentage_of_progress", sortable: false, align: "center" },
                { text: "Tendencia", value: "real_rating_tendency", sortable: false, align: "center" },
            ],
            loadingtext: this.$gettext("Cargando ranking"),
            pageCount: 0,
            ordering: "-source_date",
            page: 1,
        }
    },
    computed: {
        renderOrderingArrow() {
            return this.items && this.items.length > 1
        },
        pageCustom: {
            get() {
                if (this.pagination) {
                    return this.page;
                }
                return 1;
            },
            set(val) {
                this.page = val;
            },
        },
        itemsPerPageCustom() {
            if (this.pagination) {
                return this.itemsPerPage;
            }
            return this.items.length;
        },
    },
    watch: {
        opinions() {
            if (this.pagination) {
                this.pageCount = Math.ceil(this.items.length / this.itemsPerPage);
            }
        },
    },
    methods: {
        setOrder(val) {
            this.ordering = val;
            this.page = 1;
            this.$emit("setOrder", this.ordering);
        },
        goItemNextLevelCustom(item) {
            let itemCustom = {};
            itemCustom.id = item.center_id;
            itemCustom.name = item.center_name;
            itemCustom.level = 9;
            itemCustom.returnUrl = true;
            return this.goItemNextLevel(itemCustom, true);
        },
    }
}
</script>

<style lang="scss" scoped>
.boxOrder {
    float: right;
    padding-left: 1em;
}
</style>
