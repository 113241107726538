<template>
    <div class="pt-2">
        <InfoCompetence :data="competenceWithChildren" :title="$gettext('Comparativa')">
            <SelectCenters class="my-4" :items="centers" @setItems="setItems" :itemsChecked="centersActive"></SelectCenters>
        </InfoCompetence>
    </div>
</template>

<script>

import InfoCompetence from "@/components/competence/InfoCompetence.vue"
import SelectCenters from "@/components/competence/SelectCenters.vue"
import commonMixins from "@/mixins/common.js"
import brandMixin from "@/mixins/brand.js"
import SrvCommon from "@/services/common.js"
import SrvNetwork from "@/services/network.js"
import * as types from '@/store/mutation-types'

export default {
    mixins: [commonMixins, brandMixin],
    components: { InfoCompetence, SelectCenters },
    data() {
        return {
            competenceWithChildren: [],
            centers: [],
            centersActive: []
        }
    },
    methods: {
        async getCenters() {
            const res = await SrvCommon.getCenters()
            if (res.status === 200) {
                if (this.typeNode === 'center') {
                    this.centers = [res.data]
                } else {
                    this.centers = res.data
                }
            }
        },
        setItems(ids) {
            this.centersActive = ids
            this.getCompetenceByCenters()
        },
        async getCompetenceByCenters() {
            this.competenceWithChildren = []
            const res = await Promise.all(this.centersActive.map(async (e) => {
                return await SrvNetwork.getIndividualCompetenceGlobalByCenter(e)
            })
            )
            res.forEach(e => this.competenceWithChildren.push({ showCompetence: true, items: e.data }))
        }
    },
    created() {
        this.$store.commit(types.COMMON_DISTANCE_MAP_ACTIVE, true)
        this.$store.commit(types.COMMON_DISTANCE_MAP, 4000)
        this.$store.commit(types.COMMON_DISTANCE_MAP_MAX, 4000)
        this.getCenters()
    },
    computed: {
        distanceMap() {
            return this.$store.getters.getDistanceMap
        },
        getDistanceActive() {
            return this.$store.getters.getDistanceMapActive
        }
    },
    watch: {
        filterApply: function () {
            this.$store.commit(types.COMMON_DISTANCE_MAP_ACTIVE, true)
            this.$store.commit(types.COMMON_DISTANCE_MAP, 4000)
            this.$store.commit(types.COMMON_DISTANCE_MAP_MAX, 4000)
            this.getCenters()
            this.competenceWithChildren = []
        },
        distanceMap(newVal) {
            if (newVal && this.centersActive.length > 0) {
                this.competenceWithChildren = []
                this.getCompetenceByCenters()
            }
        },
        getDistanceActive() {
            if (this.centersActive.length > 0) {
                this.competenceWithChildren = []
                this.getCompetenceByCenters()
            }
        }
    }
}
</script>

<style scoped></style>