<template lang="pug">
.tree-menu
  div(v-if="!onelevel")
    div.boxLabel(:class="{'firsElementHide':firstElement }")
      div.labelItemTree
        span.itemTreeInfo( @click="setItem(item,getType(item),slug)" :style="indent"
          :id="getId(item)"
          :data-type="getType(item)"
          :data-slug="slug"
          :data-name="item.name"
          :data-tree="tree || item.tree"
          :data-has-delivery="item.has_delivery"
          :data-has-internet="item.has_internet"
          :data-has-competence="item.has_competence"
          :data-has-employees="item.has_employees"
          :data-has-dishkeywords="item.has_dish_keywords"
          :data-has-custom-surveys="item.has_custom_surveys"
          :data-id="item.id"
          :data-level="item.level"
          :data-backurl="getBackUrl(item)"
          :class="{'tree-active': isItemActive,'itemTreeBig':item.level >= 0 && item.level < 2, 'dropdownTreeOpenText': showChildren, 'itemTreeInfofirsElementHide':firstElement }" )
          span.labelText(v-if="!item.logo || item.level > 0") {{ label }}
          img.linkLogo(v-else :src="item.logo" height="23")

      div.dropdownTree(v-if="showDropDown" @click="toggleChildren(slug)" :data-id="item.id" :slug="slug" :ref="'ref'+id" :class="{'dropdownTreeOpen': showChildren,'itemTreeBig':item.level >= 0 && item.level < 2}")
        v-icon(right blue v-if="(item.level == 0 || item.level == 1) && showChildren") mdi-chevron-down
        v-icon(right blue v-else-if="(item.level == 0 || item.level == 1) && !showChildren") mdi-chevron-right
        v-icon(right blue v-else-if="showChildren") mdi-minus
        v-icon(right blue v-else) mdi-plus
    tree-menu.groupTree(
      v-show="showChildren"
      v-for="node in nodes"
      :nodes="items(node)"
      :label="node.name"
      :slug="slugAndId(node.id)"
      :tree="node.tree || item.tree"
      :data-slug="slugAndId(node.id)"
      :depth="depth + 1"
      :id="node.id"
      :data-tree="node.tree || item.tree"
      :data-has-delivery="node.has_delivery"
      :data-has-internet="node.has_internet"
      :data-has-competence="node.has_competence"
      :data-has-employees="node.has_employees"
      :data-has-dishkeywords="node.has_dish_keywords"
      :data-has-custom-surveys="node.has_custom_surveys"
      :data-backurl="getBackUrl(item)"
      :nodo="nodo"
      :item="node"
      :firstElement="false"
      :clickInit="false"
    )
  div(v-else)
    div.boxLabel.onelevel(v-for="(itemOneLevel, index) in nodes")
      div.labelItemTree
        span.itemTreeInfo( @click="setItem(itemOneLevel,getType(itemOneLevel),itemOneLevel.id)" :style="indent"
          :id="getId(itemOneLevel)"
          :data-type="getType(itemOneLevel)"
          :data-slug="itemOneLevel.id"
          :data-tree="tree || itemOneLevel.tree"
          :data-name="itemOneLevel.name"
          :data-has-delivery="itemOneLevel.has_delivery"
          :data-has-internet="itemOneLevel.has_internet"
          :data-has-competence="itemOneLevel.has_competence"
          :data-has-employees="itemOneLevel.has_employees"
          :data-has-custom-surveys="itemOneLevel.has_custom_surveys"
          :data-backurl="getBackUrl(item)"
          :data-id="itemOneLevel.id"
          :data-level=9
          :class="{'tree-active': isItemActiveOneLevel(itemOneLevel)}" )
          span.labelText {{ itemOneLevel.name }}

</template>
<script>
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js";

export default {
  props: ['label', 'nodes', 'tree', 'slug', 'depth', 'id', 'nodo', 'item', 'onelevel', 'firstElement', 'clickInit'],
  mixins: [commonMixins],
  name: 'tree-menu',
  data() {
    return { showChildren: false, loadingItem: false }
  },
  created() {
    if (this.firstElement) {
      this.showChildren = true
    }
  },
  mounted() {
    if (this.$store.getters.getShowTree) {
      // CUANDO NO TENEMOS PARAMCOD (AL ENTRAR LA PRIMERA VEZ) OBTENEMOS EL PRIMER ELEMENTO DEL ARBOL/LISTA DE NODOS
      this.$nextTick(() => {
        if (!this.$store.getters.getNode) {
          let itemTree = null
          let itemsTree = document.querySelectorAll('.itemTreeInfo')
          if (document.querySelector('.firsElementHide')) {
            itemTree = itemsTree[1]
          } else {
            itemTree = itemsTree[0]
          }
          let item = { id: itemTree.getAttribute('data-id'), level: itemTree.getAttribute('data-level') }
          this.setItem(item, itemTree.getAttribute('data-type'), itemTree.getAttribute('data-slug'))
        }
      })
    }
  },
  computed: {
    indent() {
      return { paddingLeft: `${(this.depth + 1) * 4}%` }
    },
    showDropDown() {
      return !!(this.item.children || this.item.centers);
    },
    isItemActive() {
      return (this.nodo === this.slug + '-C' || this.nodo === this.slug + '-N');
    },
  },
  methods: {
    getBackUrl(item) {
      return this.getType(item) === 'C' ? item.back_url : null
    },
    getLiteralCapitalize(label) {
      let labelCustom = label
      return labelCustom
    },
    // eslint-disable-next-line no-unused-vars
    slugAndId(id) {
      let slugClean = ''
      if (this.slug !== '') {
        slugClean += this.slug + '-'
      }
      slugClean += id
      return slugClean
    },
    isItemActiveOneLevel(item) {
      return parseInt(this.$store.getters.getNode) === parseInt(item.id);
    },
    toggleChildren() {
      if (this.firstElement) {
        this.showChildren = true
      } else {
        this.showChildren = !this.showChildren
      }
    },
    items(node) {
      if (node.children && node?.children?.length > 0) {
        return node.children
      } else {
        return node.centers
      }
    },
    getId(item) {
      return item.id + '-' + item.name.replaceAll(' ', '') + '-' + this.getType(item)
    },
    getType(item) {
      if (item.level >= 0 && item.level < 9) {
        return 'N'
      }
      return 'C'
    },
    // ALMACENA EN EL STORE EL ID, LEVEL, DELIVERY, COMPETENCE, BREAD_CRUMB
    async setItem(item, type, slug) {
      this.$store.commit(types.CAN_MODIFY_SERVICES_LIST, false)
      if (this.$store.getters.getShowTree && !this.loadingItem) {
        this.loadingItem = true
        this.$store.commit(types.GLOBAL_LOADING, true)
        // SETEAMOS SI TIENE DELIVERY
        let itemTreeDomActive = document.querySelector('[data-slug="' + slug + '"]')
        if (itemTreeDomActive && itemTreeDomActive.getAttribute('data-has-delivery')) {
          this.$store.commit(types.COMMON_HAS_DELIVERY, true)
        } else {
          this.$store.commit(types.COMMON_HAS_DELIVERY, false)
        }
        // SETEAMOS EL ARBOL SI ES DIFERENTE
        if (this.$store.getters.getUserInfo.trees.length > 1) {
          if (itemTreeDomActive.getAttribute('data-tree')) {
            let tree = this.$store.getters.getUserInfo.trees.filter(e => e.id == itemTreeDomActive.getAttribute('data-tree'))[0]
            this.$store.commit(types.TREE_SELECT, tree)
          }
        }

        // SETEAMOS SI TIENE COMPPETENCIA
        if (itemTreeDomActive && itemTreeDomActive.getAttribute('data-has-competence')) {
          this.$store.commit(types.COMMON_HAS_COMPETENCE, true)
        } else {
          this.$store.commit(types.COMMON_HAS_COMPETENCE, false)
        }
        // SETEAMOS SI TIENE RANKING EMPLOYEES
        if (itemTreeDomActive && itemTreeDomActive.getAttribute('data-has-employees')) {
          this.$store.commit(types.COMMON_HAS_EMPLOYEES, true)
        } else {
          this.$store.commit(types.COMMON_HAS_EMPLOYEES, false)
        }
        if (itemTreeDomActive && itemTreeDomActive.getAttribute('data-has-dishkeywords')) {
          this.$store.commit(types.COMMON_HAS_DISHKEYWORDS, true)
        } else {
          this.$store.commit(types.COMMON_HAS_DISHKEYWORDS, false)
        }
        // SETEAMOS SI TIENE custom SATISFACTION
        if (itemTreeDomActive && itemTreeDomActive.getAttribute('data-has-custom-surveys')) {
          this.$store.commit(types.COMMON_HAS_CUSTOM_SURVEYS, true)
        } else {
          this.$store.commit(types.COMMON_HAS_CUSTOM_SURVEYS, false)
        }
        // eslint-disable-next-line no-unused-vars
        let routeName = this.$route.name

        // SETEAMOS EN EL STORE EL NODO ACTUAL
        this.$store.commit(types.SET_NODE, item.id)
        //SETEAMOS EN EL STORE EL NIVEL
        if (item.level >= 0 && item.level < 9) {
          this.$store.commit(types.SET_NODE_LEVEL, item.level)
        } else {
          // ES A NIVEL DE CENTRO / RESTAURANTE
          this.$store.commit(types.SET_NODE_LEVEL, 9)
        }

        //SI ACTIVAN UN CENTRO ACTIVAMOS FILTRO MAPA COMPENTENCIA Y AUMENTEAMOS DISTANCIA A 4000
        if (this.typeNode === 'center' || ['DeliveryCompetenceCompareLocals', 'NetworkCompetenceCompareLocals'].includes(this.$route.name)) {
          this.$store.commit(types.COMMON_DISTANCE_MAP_ACTIVE, true)
          this.$store.commit(types.COMMON_DISTANCE_MAP, 4000)
          this.$store.commit(types.COMMON_DISTANCE_MAP_MAX, 4000)
        } else {
          this.$store.commit(types.COMMON_DISTANCE_MAP, 2000)
          this.$store.commit(types.COMMON_DISTANCE_MAP_MAX, 2000)
        }

        // OBTENEMOS URL A LA QUE PUEDE IR EL ITEM
        routeName = await this.controlNavigationForLevel(item)

        // Reset sites filter when browsing with tree
        delete this.$store.getters.queryParams.sitesdelivery
        this.$store.commit(types.COMMON_SITES_DELIVERY_SELECTED, [])

        // PROCESAMOS ROUTE
        let query = { ...this.$store.getters.queryParams }

        //let processResult = await processFilter(query, routeName)
        this.$store.commit(types.BREAD_CRUMB, slug)
        let param = slug + '-' + type

        query.tree = itemTreeDomActive.getAttribute('data-tree')
        const resolve = this.$router.resolve({ name: routeName, params: { paramCOD: param }, query: query })
        if (this.$route.fullPath !== resolve.href) {
          await this.$router.push({ name: routeName, params: { paramCOD: param }, query: query })
        } else {
          this.$store.commit(types.GLOBAL_LOADING, false)
        }
        this.loadingItem = false
      }
    },
    // CONTROL AL SELECCIONAR ELEMENTO DEL ARBOL
    async controlNavigationForLevel(item) {
      let routeName = this.$route.name
      if (item.level === 0 && (this.$router.history.current.name !== 'SatisfactionQuizIndicators' && this.$router.history.current.name.indexOf('SatisfactionSac') < 0)) {
        let routeParent = window.location.pathname.split('/')[1]
        if (routeParent === 'satisfaction') {
          await this.$router.push({ path: '/' + routeParent })
          return false
        }
      }
      if (item.level === 0 && (this.$router.history.current.name === 'NetworkValorationPositioning' || this.$router.history.current.name === 'NetworkValorationPositioningLast')) {
        routeName = 'NetworkValorationRanking'
      }
      // SI EL NODO ES GRUPO O NO TIENE COMPETENCIA SI ESTAMOS EN UNA DE LAS PANTALLAS DE COMPETENCIA ENVIAMOS A RESUMEN DE INTERNET
      if ((item.level === 0 || !this.hasCompetence) && (this.$router.history.current.name === 'NetworkCompetenceGlobal' || this.$router.history.current.name === 'NetworkCompetenceMap')) {
        routeName = 'NetworkResume'
      }
      // APARTADO FEEDBACK A NIVEL 0 (GRUPO ) NO SE PUEDE ACCEDER
      if (item.level === 0 && ['FeedBackOpinions'].includes(this.$router.history.current.name)) {
        routeName = 'GlobalSummary'
      }
      // A NIVEL CENTRO/RESTAURANTE SOLO SE PUEDE IR SI ES CENTRO
      if (item.level === 0 && ['LocalRestaurant'].includes(this.$router.history.current.name)) {
        routeName = 'GlobalSummary'
      }
      // A NIVEL CENTRO/RESTAURANTE SOLO SE PUEDE IR SI ES CENTRO
      if (item.level !== 1 && ['ReportsListExcel'].includes(this.$router.history.current.name)) {
        routeName = 'ReportsList'
      }
      if (item.level > 0 && ['LocalRestaurant'].includes(this.$router.history.current.name)) {
        routeName = 'LocalHome'
      }
      if (item.level === 0 && (this.$router.history.current.name === 'SatisfactionSacClaims' || this.$router.history.current.name === 'SatisfactionSacSuggestions')) {
        routeName = 'SatisfactionSacEvolution'
      }


      if (!this.hasEmployees && ['FeedBackEmployees'].includes(this.$router.history.current.name)) {
        routeName = 'FeedBackOpinions'
      }
      if (!this.$store.getters.hasDishKeywords && ['FeedBackDish'].includes(this.$router.history.current.name)) {
        routeName = 'FeedBackOpinions'
      }
      if (!this.$store.getters.getHasCustomSurveys && this.$route.name.indexOf('HealthyPoke') > -1) {
        routeName = 'GlobalSummary'
      }
      if (item.level != 1 && ['AttentionAppsReviews'].includes(this.$router.history.current.name)) {
        routeName = 'AttentionAppsIndicators'
      }
      if (!Object.keys(item).includes('level')) {
        if (this.$store.getters.getIdSection === 4) {
          routeName = 'ReportsList'
        } else if (this.$store.getters.getIdSection === 5 && (this.$router.history.current.name === 'LocalList' || this.$router.history.current.name === 'LocalStatusTasks')) {
          routeName = 'LocalRestaurant'

        } else if (['GlobalGastroRanking'].includes(this.$router.history.current.name)) {
          routeName = 'GlobalGastroRanking'
        }
      }
      // SI ESTAMOS COMO NODO CENTRO DENTRO DE VISTAS DE LOCALMARKETING Y CAMBIAMOS A NIVEL NODO SACAMOS A LOCALHOME
      if (this.$route.meta.isOnlyCenter && Object.keys(item).includes('level') && this.$route.path.indexOf('/local') == 0) {
        routeName = 'LocalHome'
      }
      return routeName
    }
  }
}
</script>
<style scoped>
.linkLogo {
  cursor: pointer;
  max-height: 30px;
  width: auto;
}
</style>
