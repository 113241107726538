import NetworkHome from "../views/Network/Home.vue";
import NetworkResume from "../views/Network/Pages/Resume.vue";
import NetworkPresenceRanking from "../views/Network/Pages/PresenceRanking.vue";
import NetworkValorationPositioning from "../views/Network/Pages/ValorationPositioning.vue";
import NetworkValorationPositioningLast from "../views/Network/Pages/ValorationPositioningLast.vue";
import NetworkValorationPositioningLast12Weeks from "../views/Network/Pages/ValorationPositioningLast12Weeks.vue";
import NetworkValoration from "../views/Network/Pages/Valoration.vue";
import NetworkGps from "../views/Network/Pages/FeelingGps.vue";
import NetworkCompetenceGlobal from "../views/Network/Pages/CompetenceGlobal.vue";
import NetworkCompetence from "../views/Network/Pages/Competence.vue";
import NetworkCompetenceMap from "../views/Network/Pages/MapCompetence.vue";
import NetworkCompetenceCompareLocals from "../views/Network/Pages/CompetenceCompareLocals.vue";
import NetworkAggregators from "../views/Network/Pages/Aggregators.vue";
import IndividualCompetence from "../views/Network/Pages/IndividualCompetence.vue";
import IndividualNetworkCompetenceCompareLocals from "../views/Network/Pages/IndividualCompetenceCompareLocals.vue";
import NetworkGoogleTrend from "../views/Network/Pages/GoogleTrend.vue";

export default {
  path: "/network",
  name: "NetworkHome",
  redirect: "/network/resume/:paramCOD?",
  component: NetworkHome,
  meta: { Auth: true },
  props: true,
  children: [
    {
      path: "resume/:paramCOD?",
      name: "NetworkResume",
      component: NetworkResume,
      props: true,
      meta: { Auth: true },
    },
    {
      path: "valoration/",
      name: "NetworkValoration",
      component: NetworkValoration,
      redirect: "/network/valoration/positioning/:paramCOD?",
      props: true,
      meta: { Auth: true },
      children: [
        {
          path: "positioning/:paramCOD?",
          name: "NetworkValorationPositioning",
          component: NetworkValorationPositioning,
          meta: { Auth: true },
          props(route) {
            return route.query || {};
          },
        },
        {
          path: "positioning-last-year/:paramCOD?",
          name: "NetworkValorationPositioningLast",
          component: NetworkValorationPositioningLast,
          meta: { Auth: true },
          props(route) {
            return route.query || {};
          },
        },
        {
          path: "positioning-last-12-weeks/:paramCOD?",
          name: "NetworkValorationPositioningLast12Weeks",
          component: NetworkValorationPositioningLast12Weeks,
          meta: { Auth: true },
          props(route) {
            return route.query || {};
          },
        },
        {
          path: "ranking/:paramCOD?",
          name: "NetworkValorationRanking",
          component: NetworkPresenceRanking,
          meta: { Auth: true },
          props(route) {
            return route.query || {};
          },
        },
      ],
    },
    {
      path: "gps/:paramCOD?",
      name: "NetworkGps",
      component: NetworkGps,
      meta: { Auth: true },
      props(route) {
        return route.query || {};
      },
    },
    {
      path: "competence/",
      name: "NetworkCompetence",
      component: NetworkCompetence,
      redirect: "/network/competence/global/:paramCOD?",
      meta: { Auth: true },
      props(route) {
        return route.query || {};
      },
      children: [
        {
          path: "global/:paramCOD?",
          name: "NetworkCompetenceGlobal",
          component: NetworkCompetenceGlobal,
          meta: { Auth: true },
          props(route) {
            return route.query || {};
          },
        },
        {
          path: "map/:paramCOD?",
          name: "NetworkCompetenceMap",
          component: NetworkCompetenceMap,
          meta: { Auth: true },
          props(route) {
            return route.query || {};
          },
        },
        {
          path: "compare-locals/:paramCOD?",
          name: "NetworkCompetenceCompareLocals",
          component: NetworkCompetenceCompareLocals,
          meta: { Auth: true, notShowDownload: true, filterRangeDistance: true },
          props(route) {
            return route.query || {};
          },
        },
      ],
    },
    {
      path: "google-trend/:paramCOD?",
      name: "GoogleTrend",
      component: NetworkGoogleTrend,
      meta: { Auth: true },
      props(route) {
        return route.query || {};
      },
    },
    {
      path: "individual-competence/",
      name: "IndividualCompetence",
      component: NetworkCompetence,
      redirect: "/network/individual-competence/global/:paramCOD?",
      meta: { Auth: true },
      props(route) {
        return route.query || {};
      },
      children: [
        {
          path: "global/:paramCOD?",
          name: "IndividualNetworkCompetenceGlobal",
          component: IndividualCompetence,
          meta: { Auth: true },
          props(route) {
            return route.query || {};
          },
        },
        {
          path: "compare-locals/:paramCOD?",
          name: "IndividualNetworkCompetenceCompareLocals",
          component: IndividualNetworkCompetenceCompareLocals,
          meta: { Auth: true, notShowDownload: true, filterRangeDistance: true },
          props(route) {
            return route.query || {};
          },
        },
      ],
    },
    {
      path: "delivery-analytics/:paramCOD?",
      name: "NetworkAggregators",
      component: NetworkAggregators,
      meta: { Auth: true },
      props(route) {
        return route.query || {};
      },
      children: [],
    },
  ],
};
