<template>
  <span class="c-rating">
    <img :src="rating" :width="size" />
  </span>
</template>

<script>
export default {
  props: {
    percent: {
      type: Number,
      required: true,
      default: 0
    },
    size: {
      type: Number,
      default: 25
    },
    customBad: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      img: {
        imgRatingBad: require('@/assets/img/glovo_rating_bad.svg'),
        imgRatingGlovoRegular: require('@/assets/img/rating_regular.png'),
        imgRatingGlovoGood: require('@/assets/img/rating_good.svg'),
        imgRatingGlovoExcellent: require('@/assets/img/rating_excellent.png')
      }
    }
  },
  computed: {
    rating() {
      const badIcon = this.customBad ? this.img.imgRatingBad : this.img.imgRatingGlovoRegular
      if (this.percent >= 0 && this.percent <= 33) {
        return badIcon
      } else if (this.percent >= 34 && this.percent <= 66) {
        return this.img.imgRatingGlovoGood
      } else if (this.percent >= 67 && this.percent <= 100) {
        return this.img.imgRatingGlovoExcellent
      }

      return badIcon
    }
  }
}
</script>

<style lang="scss" scoped>
.c-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
</style>
