<template lang="pug">
    div.margin-bottom-0.col-6
        skeleton-card(v-if="isLoading")
        v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading")
            .d-flex.headTitleGrid
                .block-title.titleGrid
                    translate.mb-0.title-card Webs of
                    span.ml-1.tDelivery Delivery
                .block-help.ml-auto
                    v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="40" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        span {{helpDeliveryWebs}}
                    v-icon.mb-1(v-if="typeNode!=='group'" @click="goFeedback('delivery')") mdi-chevron-right

            .card-content
                .row.text-left
                    #tableWebDelivery.tableGlobal
                        div.headerTableRanking.row.ma-0
                            div.headerItem.col-name
                                p
                            div.headerItem.col-nopiniones
                                p.mb-0 #[translate N. of orders]
                            div.headerItem.col-nopiniones
                                p.mb-0 {{ $gettext('N.º Reviews') }}
                            div.headerItem.col-nlocales
                                p.mb-0 #[translate No. of Comments]
                            
                            div.headerItem.col-puntuacion
                                p.mb-0 #[translate Puntuación / Satisfacción]

                        div.contentTableRanking.row.ma-0
                            p.ml-3(v-if="!data && !data[0].delivery_rating") #[translate No data]
                            div.itemContent.col-12.pa-0(v-for="(item, index) in data[0].delivery_rating" :key="index")
                                div.item.col-name
                                    .boxLogo
                                        img(:src="item.logo")
                                    span {{item.name}}
                                div.item.col-nopiniones.text-right
                                    span.mb-0.sNumber(v-if="item.n_orders") {{ item.n_orders || 0 }}
                                    span.mb-0.sNumber(v-else) -
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            span.arrow.ml-4(v-bind="attrs" v-on="on" :class="getArrow(item.n_orders, item.tend_n_orders)")
                                        span {{ item.tend_n_orders | formatNumber }}
                                div.item.col-nopiniones.text-right
                                    span.mb-0.sNumber(v-if="item.count") {{ item.count || 0 }}
                                    span.mb-0.sNumber(v-else) -
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            span.arrow.ml-4(v-bind="attrs" v-on="on" :class="getArrow(item.count, item.tend_count)")
                                        span {{ item.tend_count | formatNumber }}
                                div.item.col-nlocales.text-right
                                    span.mb-0 {{item.text_count | formatNumber}}                                    
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            span.mb-0.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.text_count, item.tend_text_count)")
                                        span {{ item.tend_text_count | formatNumber }}
                                div.item.col-puntuacion.text-right
                                    span.mb-0.sNumber(:class="getColorNote(item.rating_global)")  {{ item.site_rating | formatNumber }}
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            span.mb-0.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.site_rating, item.tend_site_rating)")
                                        span {{ item.tend_site_rating | formatNumber }}

</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import commonMixins from "@/mixins/common.js";
export default {
    props:['data'],
    mixins: [commonMixins],
    components: { SkeletonCard },
    data(){
        return {
            helpDeliveryWebs: this.$gettext('Depending on the selected period and the filters, the data of the Delivery aggregators where opinions have been received during that period are displayed and the trend with respect to the same period of the previous year is calculated. It is analyzed about the group of restaurants that marks the selection in the left menu'),
        }
    },
}
</script>

<style lang="scss" scoped>
.tableGlobal{
    .headerTableRanking{
        .headerItem{
            text-align: right;
            font-size: 0.75rem;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: right;
            img{
                cursor: pointer;
            }
        }
    }
    .contentTableRanking{
        .itemContent{
            text-align: center;
            font-size: 0.875rem;
            border-top: 1px solid #9797973d;
            height: 41px;
            display: flex;
            align-items: center;
            &:last-child{
               border-bottom: 1px solid #9797973d;
            }
        }

    }
}
#tableWebDelivery{

    .col-name{
        max-width: 30%;
        flex: 0 0 30%;
        margin-right: 2.5%;
        padding-left: 10px;
        text-align: left;
        img{
            height: 28px;
        }
        .boxLogo,span{
            display: inline-block;
            vertical-align: middle;
        }
        .boxLogo{
            width: 27%;
        }
    }
    .col-nopiniones{
        max-width: 16.5%;
        flex: 0 0 16.5%;
        padding-right: 2%;
        justify-content: right;
    }
    .col-nlocales{
        max-width: 16.5%;
        flex: 0 0 16.5%;
        padding-right: 2%;
        .sNumber{
            min-width: 50%;
            display: inline-block;
        }
        .arrow{
            margin-left: 9%;
        }
    }
    .col-puntuacion{
        max-width: 16.5%;
        flex: 0 0 16.5%;
        padding-right: 2%;
        .sNumber{
            min-width: 50%;
            display: inline-block;
        }
        .arrow{
            margin-left: 9%;
        }
    }
}

</style>
