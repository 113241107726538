export default {
    path: '/delivery/:paramCOD?',
    name: 'DeliveryHome',
    component: () => import(/* webpackChunkName: "DeliveryHome" */ '../views/Delivery/Home.vue'),
    meta: { Auth: true, disabledForAdverbia: true },
    props: true,
    children: [
      {
        path: '/delivery/resume/:paramCOD?',
        name: 'DeliveryResumeHome',
        component: () => import(/* webpackChunkName: "DeliveryResumeHome" */ '../views/Delivery/Resume/Home.vue'),
        meta: { Auth: true, disabledForAdverbia: true },
        props: true,
      },
      {
        path: '/delivery/ranking-restaurants/:paramCOD?',
        name: 'DeliveryRankingRestaurantsHome',
        component: () => import(/* webpackChunkName: "DeliveryRankingRestaurantsHome" */ '../views/Delivery/RankingRestaurants/Home.vue'),
        meta: { Auth: true, disabledForAdverbia: true },
        props: true,
      },
      {
        path: '/delivery/gps/:paramCOD?',
        name: 'DeliveryGPSHome',
        component: () => import(/* webpackChunkName: "DeliveryGPSHome" */ '../views/Delivery/Gps/Home.vue'),
        meta: { Auth: true, disabledForAdverbia: true },
        props: true,
      },
      {
        path: '/delivery/competence/:paramCOD?',
        name: 'DeliveryCompetenceHome',
        component: () => import(/* webpackChunkName: "DeliveryCompetenceHome" */ '../views/Delivery/Competence/Home.vue'),
        redirect: '/delivery/competence/global/:paramCOD?',
        meta: { Auth: true, disabledForAdverbia: true },
        props: true,
        children: [
          {
            path: '/delivery/competence/global/:paramCOD?',
            name: 'DeliveryCompetenceGlobal',
            component: () => import(/* webpackChunkName: "DeliveryCompetenceGlobal" */ '../views/Delivery/Competence/Global.vue'),
            meta: { Auth: true, disabledForAdverbia: true },
            props: true,
            children: []
          },
          {
            path: '/delivery/competence/map/:paramCOD?',
            name: 'DeliveryCompetenceMap',
            component: () => import(/* webpackChunkName: "DeliveryCompetenceMap" */ '../views/Delivery/Competence/Map.vue'),
            meta: { Auth: true, disabledForAdverbia: true },
            props: true,
            children: []
          },
          {
            path: '/delivery/competence/compare-locals/:paramCOD?',
            name: 'DeliveryCompetenceCompareLocals',
            component: () => import(/* webpackChunkName: "DeliveryCompetenceMap" */ '../views/Delivery/Competence/CompetenceCompareLocals.vue'),
            meta: {Auth: true, notShowDownload: true, filterRangeDistance: true},
            props(route) {
              return  route.query || {}
            }
            
          }
        ]
      },
      {
        path: '/delivery/posicionamiento/:paramCOD?',
        name: 'DeliveryPosicionamientoHome',
        component: () => import(/* webpackChunkName: "DeliveryPosicionamientoHome" */ '../views/Delivery/Posicionamiento/Home.vue'),
        meta: { Auth: true, disabledForAdverbia: true },
        props: true,
      },
    ]
  }