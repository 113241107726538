<template lang="pug">
div
  v-col(md="12").contentOutPadding.tablaGR
    v-data-table#tableComments(
      :headers="headers"
      :items="comments"
      :page.sync="pageCustom"
      :items-per-page="itemsPerPageCustom"
      hide-default-footer
      class="elevation-1"
      item-key="idGenerate"
      :loading="loading"
      :loading-text="loadingtext"
      @page-count="pageCount = $event"
    )
      template(v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }")
        span(v-if="header.value === 'user_name'")
          span {{ header.text }}

          .boxOrder(v-if="isDelivery")
            <sort :orderBy="'site_normalized_rating_global'" :orderByActual="ordering" @sort="setOrder($event)" />
          .boxOrder(v-else)
            <sort :orderBy="'rating_global'" :orderByActual="ordering" @sort="setOrder($event)" />
        span(v-else-if="header.value === 'source_date'")
          span {{ header.text }}
          .boxOrder
            <sort :orderBy="'source_date'" :orderByActual="ordering" @sort="setOrder($event)" />
        span(v-else) {{ header.text }}

      template(v-slot:item.user_name="{ item }")
        div.boxNoteAndName(v-if="isDelivery")
          div.d-flex.justify-center.items-center(v-if="item['site_info']['code'] === 'JUS'")
            div.d-flex.justify-center.items-center
              div.d-flex.justify-center.items-center(v-if="!item['site_rating']")
                span -
              div.d-flex.justify-center.items-center(v-else)
                just-eat-rating(:ranking="+(item['site_rating'].toFixed(2))")

          div.d-flex.justify-center.items-center(v-if="item['site_info']['code'] === 'GLV'")
            div.d-flex.justify-center.items-center
              div.d-flex.justify-center.items-center(v-if="!item['site_rating']")
                span -
              div.d-flex.justify-center.items-center.gap-0_5em(v-else)
                GlovoRating(:percent="(item['site_rating'] > 5 ? 5 : item['site_rating']) / 5 * 100" :custom-bad="true")

          div.d-flex.justify-center.items-center(v-if="item['site_info']['code'] === 'UBE'")
            div.d-flex.justify-center.items-center
              div.d-flex.justify-center.items-center(v-if="!item['site_rating']")
                span -
              div.d-flex.justify-center.items-center.gap-0_5em(v-else)
                img(:src="img.imgStar")
                span {{ item['site_rating'] | formatNumber }}
          .boxName.font-weight-med.text-center(dark :title="item.user_name") {{ item.user_name }}
        div.boxNoteAndName(v-else)
          span.boxDown(v-if="showDownComment(item)")
            img.down(:src="imgCommons.down" :class="{'rotate':item.comentariocompleto}" @click="mostrarComentario(item)")
          span.boxNote(dark)
            span(:style="{backgroundColor: getColorByNoteNormalized({note: getNoteNormalized(item.rating_global), type: 'color'})}" v-if="item.rating_global != null") {{ getNoteNormalized(item.rating_global) }}
            span.withOutNote(v-else) -
          .boxName.font-weight-med(dark :title="item.user_name") {{ item.user_name }}

      template(v-slot:item.text="{ item, index }")
        div#block-comment(:data-review-id="item.id")
          tags-external-review(:tags="item.tags" :site="item.site" :siteInfo="item.site_info")
          p.px-4.border-20(v-if="item.link_to_publication_img" )
            v-img(:src="item.link_to_publication_img" max-width="150" @mousedown="picture = item.link_to_publication_img; showImage = true;" v-on:error="item.link_to_publication_img = null")
              template(v-slot:default)
                v-row(class="fill-height ma-0" align="center" justify="center")
                  v-icon(color="white") mdi-eye

          p.comentario(id="parraf" :class="{'nocomment': !item.text}" dark)
            span(v-html="getTextOpinion(item)")
            div.comentario.translation(v-if="item.review_translation")
              strong {{ $gettext('Translation') }}
              p(v-html="item.review_translation.text")
          div(v-if="isDelivery")
            tags-internal-review(:tags="item.categories" :isDelivery="true" :hasEditTags="hasEditTags" :indexComment="index" :idComment="item.id" 
            @addTagOk="addNewTagComment"
            @removeTag="removeTagInternal" @linkFilterByCategory="linkFilterByCategory" :complaintProducts="item['complaint_products']")
          div(v-else)
            tags-internal-review(:tags="item.categories"  :isDelivery="false" :hasEditTags="hasEditTags" :indexComment="index" :idComment="item.id" 
            @addTagOk="addNewTagComment"
            @removeTag="removeTagInternal" @linkFilterByCategory="linkFilterByCategory")    
          div.respuesta(v-show="item.showReply")
            div(v-if="item.site_info.code === 'TRI'")
              div(v-if="item.error")
                div.d-flex.pt-2
                  div.d-flex.align-center {{ item.messageError }}
                  div.d-flex.flex-column.justify-end.align-start.px-4
                    v-btn.py-2.my-1(data-site="TripAdvisor" data-listener="false" color='primary' tile min-width="100" max-height="30" @click='item.error = undefined')
                      | #[translate Retry]

              div(v-else-if="item.allow_reply && userCanReply")
                div(v-if="item.reply && item.reply.text && item.reply.text.length")
                  div.px-2.font-weight-bold {{ item.reply.creator_email || 'Response' }}
                  div.d-flex.pt-2
                    div.flex-fill.px-4.py-2.rounded.bg-EBEBEB {{ item.reply.text }}
                    div.d-flex.flex-column.justify-end.align-start.px-4
                div(v-else)
                  answers-chat-gpt(v-if="item.text" :hasPremium="canLoadAnswersChatGPT" @setAnswer="saveReplyChatGPT" :item="item")
                  div.d-flex.align-center.px-2.font-weight-bold.column-gap-0_5em(@mousedown="openDefaultAwnsers = true; currentItem = item;")
                    v-icon.cursor-pointer(color="terciary") mdi-square-edit-outline
                    span.cursor-pointer
                      | #[translate Pick up a default answer]
                  div.d-flex.pt-2
                    textarea.flex-fill.px-4.py-2.rounded.bg-white.border-B7C4CB(v-model="item.unsavedReply" @mousedown="currentItem = item" @input="autoSize")
                    div.d-flex.flex-column.justify-end.align-start.px-4
                      v-btn.py-2.my-1.btn-awnser-tp(data-site="TripAdvisor" data-listener="false" color='primary' tile min-width="100" max-height="30" :loading="item.saving" :disabled="!item.unsavedReply || item.saving" @click='currentItem = item')
                        | #[translate Answer]
              div(v-else)
                div(v-if="item.reply")
                  div.px-2.font-weight-bold {{ item.reply.creator_email || 'Response' }}
                  div.d-flex.pt-2
                    div.flex-fill.px-4.py-2.rounded.bg-EBEBEB {{ item.reply.text }}
                    div.d-flex.flex-column.justify-end.align-start.px-4(v-if="item.allow_delete_reply")
                      div.pa-1.rounded.bg-EBEBEB.cursor-pointer(@click='deleteReply')
                        v-icon(color="terciary" :disabled="deletingReplyStatus || !item.allow_delete_reply") mdi-delete-outline
            div(v-else-if="item.site_info.status.linked !== 'Connected' || item.site_info.status.status !== 'Active'")
              div.d-flex.justify-center.align-center
                v-btn.py-2.my-1(v-if="item.site_info.name === 'Google'" :loading="settinUpGoogle" color='primary' tile min-width="100" max-height="30" @mousedown="googleSetup(item)")
                  | #[translate Google Setup]
                v-btn.py-2.my-1(v-if="['Facebook','Instagram'].includes(item.site_info.name)" :loading="settinUpFacebook"  color='primary' tile min-width="100" max-height="30" @mousedown="facebookSetup(item)")
                  | #[translate Facebook Setup]
            div(v-else-if="item.allow_reply && userCanReply")
              div(v-if="item.reply && item.reply.text && item.reply.text.length")
                div.px-2.font-weight-bold {{ item.reply.creator_email || 'Response' }}
                div.d-flex.pt-2
                  div.flex-fill.px-4.py-2.rounded.bg-EBEBEB {{ item.reply.text }}
                  div.d-flex.flex-column.justify-end.align-start.px-4(v-if="item.site_info.code !== 'FBK'")
                    div.pa-1.rounded.bg-EBEBEB.cursor-pointer(@click='deleteReply(item)')
                      v-icon(color="terciary" :disabled="deletingReplyStatus || !item.allow_delete_reply") mdi-delete-outline
              div(v-else)
                answers-chat-gpt(v-if="item.text" :hasPremium="canLoadAnswersChatGPT" @setAnswer="saveReplyChatGPT" :item="item")
                div.d-flex.align-center.px-2.font-weight-bold.column-gap-0_5em(@mousedown="openDefaultAwnsers = true; currentItem = item;")
                  v-icon.cursor-pointer(color="terciary") mdi-square-edit-outline
                  span.cursor-pointer
                    | #[translate Pick up a default answer]
                div.d-flex.pt-2
                  textarea.flex-fill.px-4.py-2.rounded.bg-white.border-B7C4CB(v-model="item.unsavedReply" @mousedown="currentItem = item" @input="autoSize")
                  div.d-flex.flex-column.justify-end.align-start.px-4
                    v-btn.py-2.my-1(color='primary' tile min-width="100" max-height="30" :loading="savingReplyStatus" :disabled="!item.unsavedReply || savingReplyStatus" @click='saveReply')
                      | #[translate Answer]
            div(v-else)
              div(v-if="item.reply")
                div.px-2.font-weight-bold {{ item.reply.creator_email || 'Response' }}
                div.d-flex.pt-2
                  div.flex-fill.px-4.py-2.rounded.bg-EBEBEB {{ item.reply.text }}
                  div.d-flex.flex-column.justify-end.align-start.px-4(v-if="item.allow_delete_reply && item.site_info.code !== 'FBK'")
                    div.pa-1.rounded.bg-EBEBEB.cursor-pointer(@click='deleteReply(item)')
                      v-icon(color="terciary" :disabled="deletingReplyStatus || !item.allow_delete_reply") mdi-delete-outline
        div#block-comment(v-if="item.showReply && item.historic_reviews")
          div(v-for="history in item.historic_reviews" class="d-flex pa-2" style="border-top: 2px solid #E5E6E7; background-color: #fafafa")
            div.boxNoteAndName
              span.boxNote(dark)
                span(:style="{backgroundColor: getColorByNoteNormalized({note: getNoteNormalized(history.site_rating * 2), type: 'color'})}" v-if="history.site_rating") {{ getNoteNormalized(history.site_rating * 2) }}
            div.ml-4.flex-1
              strong {{ history.source_date | moment("DD/MM/YYYY") }}
              p.comentario.mt-2 {{ history.text }}
              div(v-if="history?.historicreviewresponse?.creator_email && history?.historicreviewresponse?.text")
                span {{ $gettext('respondido por') }} 
                strong {{ history?.historicreviewresponse?.creator_email }} 
                span el {{ history?.historicreviewresponse?.source_date | moment("DD/MM/YYYY") }}
                p {{ history?.historicreviewresponse?.text }} 
      template(v-slot:item.center="{ item }")
        router-link.btnCentro.link-text(v-if="typeNode !== 'center'" :to="goItemNextLevelCustom(item)") {{ item.center.name }}
        span.btnCentro(v-else) {{ item.center.name }}
      template(v-slot:item.source_date="{ item }")
        span.text-date {{ item.source_date | moment("DD/MM/YYYY") }}
      template(v-slot:item.site_logo="{ item }")
        div.text-center
          img(:src="item.site_logo")
      template(v-slot:item.reply="{ item }")
        v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top :disabled="item.site_info.code === 'TRI'")
          template(v-slot:activator="{ on, attrs }")
            div.text-center(v-bind="attrs" v-on="on")
              //img.cursor-pointer(v-if="item.site_info && item.site_info.code === 'TRI' && item.showByPlugin && (item.reply || (item.allow_reply && userCanReply))" :src="(item.reply !== null ? img.replyYes:img.replyNo)" width="24" @click="showReply(item)")
              //img.cursor-pointer(v-else-if="item.site_info && item.site_info.code === 'TRI' && !item.showByPlugin && (item.reply || (item.allow_reply && userCanReply))" :src="(item.reply !== null ? img.replyYes:img.replyNo)" width="24" :style="{ opacity: 0.5, cursor: 'default' }")
              a(target="_blank" :href="item.url" v-if="item.site_info && item.site_info.code === 'TRI' && item.reply === null" )
                img.cursor-pointer(:src="img.openTri" width="24")
              img.cursor-pointer(v-else-if="item.site_info && item.site_info.code === 'TRI' && item.reply !== null" :src="img.replyYes" width="24" @click="showReply(item)")
              img.cursor-pointer(v-else="(item.reply || (item.allow_reply && userCanReply))" :src="imgToReply(item)" width="24" @click="showReply(item)")
          span(v-if="item.reply == null && item.site_info.code !== 'TRI'") {{ $gettext('Reply') }}
          span(v-else-if="item.site_info.code === 'TRI'" )
          span(v-else="item.reply") {{ $gettext('See answer') }}

  //PAGINACION
  v-row#itemsPagePagination(v-if="pageCount > 1")
    v-col.text-left(md="8")
    v-col.text-right(md="4")
      v-pagination.paginationFooter(v-if="pagination && comments"
        v-model="page"
        :length="pageCount"
        @input="handlePageChange"
        )
  facebook-locations(v-if="facebookConfig.app_id && center" @update="updateItem" :selected.sync="facebookSelected" :center.sync="center" :current-facebook-linkeded="currentFacebookLinkeded" :app-id="facebookConfig.app_id" :api-version="facebookConfig.api_version" :open.sync="openFacebookLocations")
  google-link(:open.sync="openGoogleLink" @update="updateItem")
  awnsers(:open.sync="openDefaultAwnsers" @onSelect="addDefaultAwnser")

  v-dialog(v-model="showImage" max-width="650")
    v-card
    v-toolbar.ligthbox-header(dense color="elevation-0")
      v-spacer
      v-btn(icon color="black" @click.native="showImage = false")
      v-icon(color="white") mdi-close
    v-row(no-gutters)
      v-col(cols="12")
      v-row(no-gutters align="center" justify="center")
        v-img(:src="picture" contain)
</template>

<script>
import commonMixins from "@/mixins/common.js";
import SrvGlobal from "@/services/local";
import SrvFeedback from "@/services/feedback.js";
import FacebookLocations from "@/components/local/FacebookLocations";
import GoogleLink from "@/components/local/GoogleLink";
import Awnsers from "@/components/common/Awnsers";
import GlovoRating from "@/components/common/GlovoRating.vue";
import tagsExternalReview from "@/components/common/tagsExternalReview.vue";
import tagsInternalReview from "@/components/common/tagsInternalReview.vue";
import AnswersChatGpt from "@/components/feedback/AnswersChatGPT.vue";
import Sort from "@/components/common/Sort.vue"
import JustEatRating from "@/components/common/justEatRating.vue";

export default {
  mixins: [commonMixins],
  components: { GlovoRating, Awnsers, GoogleLink, FacebookLocations, tagsExternalReview, tagsInternalReview, AnswersChatGpt, Sort, JustEatRating }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  props: {
    employedId: {
      type: Number,
      default: null,
      required: false,
    },
    comments: {
      type: Array,
      default: null,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
      required: true,
    },
    pagination: {
      type: Boolean,
      default: false,
      required: false,
    },
    isDelivery: {
      type: Boolean,
      default: false,
      required: false,
    },
    notResponse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showImage: false,
      showAddCategories: false,
      picture: "",
      page: 1, // PAGINA EN LA QUE ESTAMOS
      pageCount: 0, // PAGINAS TOTALES
      itemsPerPage: 10, // ELEMENTOS POR PAGINA
      img: {
        imgStar: require("@/assets/img/star.svg"),
        sort: require("@/assets/img/mini_arrow_shack_disable.svg"),
        sortActive: require("@/assets/img/mini_arrow_shack_active.svg"),
        replyNo: require("@/assets/img/ico-reply-no.svg"),
        replyYes: require("@/assets/img/ico-reply-yes.svg"),
        replyNoHistoric: require("@/assets/img/ico-reply-no-historic.svg"),
        replyYesHistoric: require("@/assets/img/ico-reply-yes-historic.svg"),
        changedReplyYesHistoric: require("@/assets/img/ico-changed-reply-yes-historic.svg"),
        openTri: require("@/assets/img/open-tri.svg"),
      },
      ordering: "-source_date",
      options: {}, // OPCIONES DE V-TABLE
      loadingtext: this.$gettext("Loading comments"),
      totalWidth: 0,
      nextScrollUrl: null,
      nextCall: false,
      opinionCounts: "",
      facebookConfig: {
        app_id: undefined,
        api_version: undefined,
      },
      currentItem: undefined,
      center: undefined,
      openFacebookLocations: false,
      facebookSelected: undefined,
      currentFacebookLinkeded: [],
      savingReplyStatus: false,
      deletingReplyStatus: false,
      currentFacebookToLink: undefined,
      openGoogleLink: false,
      settinUpGoogle: false,
      settinUpFacebook: false,
      openDefaultAwnsers: false,
      mocking: false,
    };
  },
  mounted() {
    window.openTripAdvisor = this.openTripAdvisor;
    window.closeTripAdvisor = this.closeTripAdvisor;
    window.savingTripAdvisor = this.savingTripAdvisor;
    window.commitTripAdvisor = this.commitTripAdvisor;
    window.showErrorTripAdvisor = this.showErrorTripAdvisor;
  },
  watch: {
    totalWidth: function () {
      return this.totalWidth;
    },
    comments(comments) {
      if (this.pagination) {
        this.pageCount = Math.ceil(this.comments.length / this.itemsPerPage);
      }
      const data = {};
      comments.forEach((item) => (data[item.id] = item));
      window.data = data;
    },
    page() { },
  },
  computed: {
    headers() {
      let heads = [
        // CABECERAS DE LA TABLE COMENTARIOS
        {
          text: this.$gettext("NOTE"),
          align: "start",
          sortable: false,
          value: "user_name",
          width: "5%",
        },
        { text: this.$gettext("COMMENT"), value: "text", sortable: false, width: "55%" },
        { text: "CENTRO", value: "center", sortable: false, width: "9%" },
        { text: this.$gettext("DATE"), value: "source_date", sortable: false, width: "9%" },
        { text: this.$gettext("SITE"), value: "site_logo", sortable: false, width: "6%" },
        { text: this.$gettext("RESP."), value: "reply", sortable: false, width: "6%" },
      ];
      if (this.notResponse) {
        heads = heads.filter((e) => e.value != "reply");
      }
      return heads;
    },
    hasEditTags() {
      return true;
    },
    itemsPerPageCustom() {
      if (this.pagination) {
        return this.itemsPerPage;
      }
      return this.comments.length;
    },
    pageCustom: {
      get() {
        if (this.pagination) {
          return this.page;
        }
        return 1;
      },
      set(val) {
        this.page = val;
      },
    },
    userCanReply() {
      if (this.mocking) {
        return true;
      } else {
        return JSON.parse(window.localStorage.getItem("_user_info")).can_reply_reviews;
      }
    },
    canLoadAnswersChatGPT() {
      return JSON.parse(window.localStorage.getItem("_user_info")).can_use_chatgpt_to_answer;
    },
    opinionsCategories() {
      let categoriesCrud = [];
      let selectedCat = this.$store.getters.getTypesSelected ? this.$store.getters.getTypesSelected : "internet";

      if (this.$store.getters.getCategories[selectedCat]) {
        categoriesCrud = this.$store.getters.getCategories[selectedCat].categories;
      }

      return categoriesCrud;
    },
    opinionsCategoriesSelected() {
      return this.$store.getters.getOpinionsCategoriesSelected.map((c) => c.value);
    },
  },
  methods: {
    imgToReply(item) {
      let img = null;
      const hasHistorical = item.historic_reviews.length > 0;
      const wasChanged = item.historic_reviews.length ? item.historic_reviews[0].site_rating !== item.site_rating : false
      if (item.reply !== null) {
        img = hasHistorical ? wasChanged ? this.img.changedReplyYesHistoric : this.img.replyYesHistoric : this.img.replyYes;
      } else {
        img = hasHistorical ? this.img.replyNoHistoric : this.img.replyNo;
      }
      return img;
    },
    async removeTagInternal(data) {
      this.comments[data.indexComment].categories = this.comments[data.indexComment].categories.filter((c) => c.id != data.category.id);
      const res = await SrvFeedback.removeOrAddCategorie({ review_id: this.comments[data.indexComment].id, category_id: data.category.id, action: "delete", review_type: !this.isDelivery ? "internet" : "delivery" });
      if (res.status === 200) {
        this.comments[data.indexComment].categories = this.comments[data.indexComment].categories.filter((c) => c.id != data.category.id);
      }
    },
    addNewTagComment(data) {
      this.comments[data.indexComment].categories.push(data.category);
    },
    async addTagInternal() {
    },

    // SE ACTUALIZA EL NÚMERO DE PAGINA
    handlePageChange(value) {
      this.page = value;
      // this.getComments()
    },
    showErrorTripAdvisor(id, message = null) {
      this.$set(window.data[id], "error", true);
      this.$set(window.data[id], "messageError", message ? message : "¡Ha ocurrido un error!");
    },
    async commitTripAdvisor(id) {
      await this.sleep(1000);
      if (!window.data[id].reply) {
        this.$set(window.data[id], "reply", {});
      }
      this.$set(window.data[id].reply, "text", window.data[id].unsavedReply);
      this.$set(window.data[id], "unsavedReply", undefined);
    },
    savingTripAdvisor(id, status) {
      this.$set(window.data[id], "saving", status);
    },
    openTripAdvisor() {
      this.comments.forEach((item) => {
        if (item.site_info.code === "TRI" && item.allow_reply && this.userCanReply) {
          item.showByPlugin = true;
        }
      });
    },
    closeTripAdvisor() {
      this.comments.forEach((item) => {
        if (item.site_info.code === "TRI") {
          item.showReply = false;
        }
      });
    },
    addDefaultAwnser(awnser) {
      this.currentItem.unsavedReply = awnser;
    },
    updateItem() {
      // Frist mockup
      // this.$set(this.currentFacebookToLink.site_info.status, "linked", "Connected")
      // this.$set(this.currentFacebookToLink.site_info.status, "status", "Active")

      // Reload All
      location.reload();
    },
    autoSize(inputEvent) {
      inputEvent.target.style.height = "";
      inputEvent.target.style.height = inputEvent.target.scrollHeight + 2 + "px";
    },
    saveReplyChatGPT(info) {
      this.currentItem = info.item;
      this.currentItem.unsavedReply = info.text;
    },
    async saveReply() {
      this.savingReplyStatus = true;
      if (this.$config.featureConfig.socialReply) {
        const { status, data } = await SrvGlobal.setReply(this.currentItem.unsavedReply, this.currentItem.id);
        if (status === 200 && data.success) {
          this.$set(this.currentItem, "reply", data.reply);
          this.$set(this.currentItem, "unsavedReply", undefined);
        }
      } else {
        await this.sleep(3000);
        if (!this.currentItem.reply) {
          this.currentItem.reply = {};
        }
        window.reply = this.currentItem.unsavedReply;
        this.$set(this.currentItem.reply, "text", this.currentItem.unsavedReply);
        this.$set(this.currentItem, "unsavedReply", undefined);
      }
      this.savingReplyStatus = false;
    },
    async deleteReply(item) {
      item && (this.currentItem = item);
      this.deletingReplyStatus = true;
      if (this.$config.featureConfig.socialReply) {
        const { status, data } = await SrvGlobal.deleteReply(this.currentItem.reply.id);
        if (status === 200 && data.success) {
          this.$set(this.currentItem, "reply", null);
          this.$set(this.currentItem, "unsavedReply", undefined);
        }
      } else {
        await this.sleep(3000);
        this.$set(this.currentItem, "allow_reply", true);
        this.$set(this.currentItem, "showReply", false);
        this.$set(this.currentItem, "reply", null);
        this.$set(this.currentItem, "unsavedReply", undefined);
      }
      this.deletingReplyStatus = false;
    },
    async googleSetup(item) {
      this.settinUpGoogle = true;
      this.center = item.center.id;
      this.currentGooleToLink = item;

      this.$nextTick(() => {
        this.openGoogleLink = true;
        this.settinUpGoogle = false;
      });
    },
    async facebookSetup(item) {
      this.settinUpFacebook = true;
      !this.facebookConfig && (await this.getLocalMarketing());
      !this.currentFacebookLinkeded && (await this.getCurrentFacebookLinkeded());
      this.facebookSelected = item.site_info.status.page_id;
      this.center = item.center.id;
      this.currentFacebookToLink = item;

      this.$nextTick(() => {
        this.openFacebookLocations = true;
        this.settinUpFacebook = false;
      });
    },
    async getLocalMarketing() {
      const { status, data } = await SrvGlobal.getLocalMarketing();
      if (status === 200) {
        this.facebookConfig = data.facebook_config;
      }
    },
    async getCurrentFacebookLinkeded() {
      const { status, data } = await SrvGlobal.getCurrentFacebookLinkeded();
      if (status === 200) {
        this.currentFacebookLinkeded = data.face_centers;
      }
    },
    downloadExcelCustom() {
      let params = { ordering: this.ordering };
      this.downloadExcel(params, this.opinionCounts);
    },
    goItemNextLevelCustom(item) {
      let itemCustom = {};
      itemCustom.id = item.center.id;
      itemCustom.name = item.center.name;
      itemCustom.level = 9;
      itemCustom.returnUrl = true;
      return this.goItemNextLevel(itemCustom, true);
    },
    showReply(item) {
      item.showReply = !item.showReply;
      this.currentItem = item;
    },
    getTextOpinion(item) {
      let text = "";
      if (item.text !== null && item.text !== "") {
        text += item.text.replace("\n", "<br>");
        text = text.replace("Positivo", "<b>Positivo</b>");
        text = text.replace("Negativo", "<b>Negativo</b>");
      }
      if (item.text === null && item.tags && item.tags.length === 0) {
        text = this.$gettext("The user has not written a review.");
      }
      return text;
    },
    setOrder(val) {
      this.ordering = val;
      this.page = 1;
      this.$emit("setOrder", this.ordering);
    },
    showDownComment: function (item) {
      if (item.text && item.text.length > 80) {
        return true;
      }
      return true;
    },
    // SE PULSA PARA DESPLEGAR COMENTARIO COMPLETO
    // atributo "comentariocompleto mapeado en la petición al api"
    mostrarComentario: function (item) {
      item.comentariocompleto = item.comentariocompleto !== true;
      if ((item.reply || (item.allow_reply && this.userCanReply))) {
        item.showReply = false;
      } else {
        item.showReply = !item.showReply;
      }
    },
    linkFilterByCategory(category) {
      this.$emit("linkFilterCategory", category);
    },
  },
};
</script>

<style lang="scss" scoped>
.translation {
  border-top: 1px solid #000;
  padding-top: 5px;

  p {
    background-color: #ebebeb;
  }
}

.ligthbox-header {
  position: absolute;
  width: 100%;
  z-index: 999;
  background: transparent !important;
}

.border-20 .v-image {
  border-radius: 5%;
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.respuesta {
  padding: 1em;
  border-top: solid #e5e6e7 2px;
}

.categoriesCoverManager {
  padding-left: 1em;

  .boxIcon {
    background-color: #eaeaea;
    border-radius: 15px;
    padding: 4px 10px;
    font-size: 0.75rem;
    color: #666;
    margin-right: 7px;
    margin-bottom: 15px;
    display: inline-block;
    max-width: 90px;

    svg,
    span {
      vertical-align: middle;
    }

    span {
      color: #ffffff;
    }
  }
}

.bgRed {
  background-color: #d61a23;
}

.bgOrange {
  background-color: #fcae19;
}

.bgGreen {
  background-color: #89c445;
}

.boxOrder {
  float: right;
}

.boxNoteAndName {
  .boxDown {
    width: 10%;
    text-align: center;

    .down {
      margin-top: 5px;
      display: inline-block;
      transition: all ease 0.3s;
      cursor: pointer;

      &.rotate {
        transform: rotate(90deg);
      }
    }
  }

  .boxNote {
    width: 79%;
    margin-left: 10%;

    span {
      color: #fff;
      border-radius: 5px;
      width: 38px;
      padding: 4px 0;
      display: inline-block;
      text-align: center;

      &.withOutNote {
        background-color: #eaeaea;
        color: #000;
      }
    }
  }

  .boxDown,
  .boxNote {
    display: inline-block;
    vertical-align: middle;
  }

  .boxName {
    color: #52575a;
    margin: 5px 0 0 0;
    font-size: 0.75rem;
    line-height: 0.75rem;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
  }
}

.comentario {
  transition: all ease 0.3s;
  font-size: 0.813rem;
  margin-top: -4px;

  &.comentarioRecortado {
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    min-width: 0;
  }
}

.tipo {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 3px;
  margin: 0 auto;
}

.filters {
  padding: 15px 0;
}

.block-count {
  font-size: 1.125rem;
}

.block-head {
  border-bottom: 1px solid #dfe3e6;
}
</style>
