import { render, staticRenderFns } from "./OneColorBarWithText.vue?vue&type=template&id=78b92104&scoped=true&lang=pug"
import script from "./OneColorBarWithText.vue?vue&type=script&lang=js"
export * from "./OneColorBarWithText.vue?vue&type=script&lang=js"
import style0 from "./OneColorBarWithText.vue?vue&type=style&index=0&id=78b92104&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78b92104",
  null
  
)

export default component.exports