<template lang="pug">
    .card-content
        .row.text-left.mt-2
            div.headerChart
                h3.nreviews {{ $gettext('NUMBER OF OPINIONS') }}
                h3 {{ $gettext('VALORATION') }}
            chart-evolution-main-number-opinion(:chartData="getChartData()" style="width:100%; height:280px")
</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import ChartEvolutionMainNumberOpinion from '@/components/charts/EvolutionMainNumberOpinion.vue'
import commonMixins from "@/mixins/common.js";
export default {
    props:['chartData', 'isDelivery'],
    mixins: [commonMixins],
    components: { SkeletonCard, ChartEvolutionMainNumberOpinion },
    methods: {
        getChartData (){
            return {
                labels: this.chartData.months,
                datasets: [{
                    data: this.ratings,
                    label: this.$gettext('Valoration'),
                    yAxisID: "y-axis-1",
                    fill: false,
                    borderColor: '#9b9b9b',
                    borderWidth: 1,
                    pointBackgroundColor: this.pointBackgroundColors,
                    pointBorderColor: this.pointBackgroundColors,
                    pointRadius: 5,
                    datalabels: {
                        padding: 3,
                        align: 'end',
                        anchor: 'end',
                    },
                    }, {
                    data: this.chartData.n_reviews,
                    label: this.$gettext('Number of Opinions'),
                    yAxisID: "y-axis-2",
                    fill: false,
                    borderColor: '#4a90e2',
                    pointBackgroundColor: '#4a90e2',
                    pointRadius: 4,
                    datalabels: {
                        display: false,
                        padding: 20
                    },
                }]
            }
        }
    },
    computed:{
        pointBackgroundColors(){
            let colors = []
            for (let i = 0; i < this.chartData.ratings.length; i++) {
                colors.push(this.getColorByNoteNormalized({ note: this.chartData.ratings[i], type: 'color'}))
            }
            return colors
        },
        ratings(){
            // tenemos que integrar para webedia el tema del nuevo campo de notas para adverbia
            let numbers = this.chartData.ratings
            let numbersFormat
            if (numbers) {
                numbersFormat = numbers.map((number) => {
                return this.$options.filters.formatNumber(number,1,1).replace(',','.')
            })
            }
            return numbersFormat
        }
    }

}
</script>

<style lang="scss" scoped>
.headerChart{
    width:100%;
    display:block;
    font-size:0.75rem;
}
h3{
    float: left;
    font-weight: normal;
    padding: 0 20px;
    font-size: 0.70rem;
    &.nreviews {
        float:right;
        color: #4a90e2;
    }
}
</style>
