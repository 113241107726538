<template >
    <div class="margin-bottom-0 col-6">
        <skeleton-card v-if="isLoading"></skeleton-card>
        <v-card class="gridFull ma-0" v-if="!isLoading">
            <div class="d-flex headTitleGrid">
                <div class="block-title titleGrid">
                    <span class="mb-0 title-card">
                    {{ $gettext('Opinion websites') }}
                    </span>
                </div>
                <div class="block-help ml-auto"></div>
            </div>
            <div class="card-content">
                <div class="tablaWebOpinionesNotaPorSitio">
                    <div class="header">
                        <div class="rowtbl d-flex">
                            <div class="coltbl colname"></div>
                            <div class="coltbl colValoracionPeriodo">Valoración del <br /> periodo</div>
                            <div class="coltbl colValoracionHistorica">Valoración histórica</div>
                            <div class="coltbl colNumeroOpinionesDelPeriodo">Nº opiniones del periodo</div>
                            <div class="coltbl colNumeroOpinionesHistorico">Nº opiniones histórico</div>
                            <div class="coltbl colNumeroComentariosDelPeriodo">Nº comentarios del periodo</div>
                            <!--<div class="coltbl colHistoricoComentarios">Histórico comentarios</div>-->
                        </div>
                    </div>
                    <div class="body">
                        <div class="rowtbl d-flex items-center" v-for="(row, index) in data[0].rating" :key="index">
                            <div class="coltbl colname d-flex items-center"><img :src="row.logo" class="mr-2" /> {{ row.name }}</div>
                            <div class="coltbl colValoracionPeriodo items-center flex-column text-center">
                                <google-rating v-if="row.code === 'GOO'" :nota="row.site_rating"></google-rating>
                                <tryp-advisor-rating v-if="row.code === 'TRI'" :nota="row.site_rating"></tryp-advisor-rating>
                                <p class="mb-0">{{ row.site_rating | formatNumber(1,1) }}</p>
                            </div>
                            <div class="coltbl colValoracionHistorica items-center flex-column text-center">
                                <google-rating v-if="row.code === 'GOO'" :nota="row.h_site_rating"></google-rating>
                                <tryp-advisor-rating v-if="row.code === 'TRI'" :nota="row.h_site_rating"></tryp-advisor-rating>
                                <p class="mb-0">{{ row.h_site_rating | formatNumber(1,1) }}</p>
                            </div>
                            <div class="coltbl colNumeroOpinionesDelPeriodo">
                                {{ row.count | formatNumber(0,0) }}
                                <v-tooltip right color="#222226" :open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="mb-0 arrow ml-2" v-bind="attrs" v-on="on" :class="getArrow(row.count, row.tend_count)"></span>
                                    </template>
                                    <span>{{ row.tend_count | formatNumber }} </span>
                                </v-tooltip>
                                            
                            </div>
                            <div class="coltbl colNumeroOpinionesHistorico pr-2">{{ row.h_n_reviews | formatNumber(0,0) }}</div>
                            <div class="coltbl colNumeroComentariosDelPeriodo pr-2">
                                {{ row.text_count | formatNumber(0,0) }}
                                <v-tooltip right color="#222226" :open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="mb-0 arrow ml-2" v-bind="attrs" v-on="on" :class="getArrow(row.text_count, row.tend_text_count)"></span>
                                    </template>
                                    <span>{{ row.tend_text_count | formatNumber }} </span>
                                </v-tooltip>
                            </div>
                            <!--<div class="coltbl colHistoricoComentarios pr-2">{{ row.tend_text_count | formatNumber(0,0) }}</div>-->
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
    </div>

</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import commonMixins from "@/mixins/common.js";
import googleRating from "@/components/common/googleRating.vue"
import trypAdvisorRating from "@/components/common/trypadvisorRating.vue"
export default {
    props:['data'],
    mixins: [commonMixins],
    components: { SkeletonCard, googleRating, trypAdvisorRating },
    data(){
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.tablaWebOpinionesNotaPorSitio{
    margin: 0 -10px;
    .header{
        font-size: 10px;
        .rowtbl{
            .coltbl{
                justify-content: center;
                text-align: center;
                font-size: 10px;
            }
        }
    }
    .body{
        font-size: 14px;
        text-align: right;
            .coltbl{
                padding: 5px 2px;
                display: flex;
                align-self: stretch;
                align-items: center;
                justify-content: right;
            }
    }
    .rowtbl{
        border-bottom: 1px solid #e8e8e8;
        
        .colname, .colValoracionPeriodo, .colValoracionHistorica, .colNumeroOpinionesHistorico{
            border-right: 1px solid #e8e8e8;
        }
        .colValoracionPeriodo, .colValoracionHistorica{
            justify-content: center;
        }
        .colValoracionHistorica, .colNumeroOpinionesHistorico, .colHistoricoComentarios{
            font-size: 12px;
        }
        .colname{
            width: 26%;
            justify-content: start;
            img{
                height: 28px;
            }
        }
        .colValoracionPeriodo{
            width: 19%;
        }
        .colValoracionHistorica{
            width: 13%;
        }
        .colNumeroOpinionesDelPeriodo{
            width: 14%;
        }
        .colNumeroOpinionesHistorico{
            width: 14%;
        }
        .colNumeroComentariosDelPeriodo{
            width: 14%;
        }
        .colHistoricoComentarios{
            width: 12%;
        }
    }
}
</style>