<template lang="pug">
div.bar-container(:style="`height: ${height}px`")
    div.progress(:style="`max-width:${width}%;`")
</template>

<script>
export default {
    props: {
        width: {
            default: 0
        },
        height: {
            default: 8
        }
    },
}
</script>


<style lang="scss" scoped>
.bar-container {
    background: #F1F3F4;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;

    .progress {
        background: #FBBC04;
        width: 0%;
        height: inherit;
        animation: 1.5s ease-in forwards width-animation;

        @keyframes width-animation {
            0% {
                width: 0px;
            }

            100% {
                width: 100%;
            }

        }
    }

}
</style>