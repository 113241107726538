<template lang="pug">
div.margin-bottom-0(:class="col")
    skeleton-card(v-if="isLoading && !dataSummary.length")
    v-card.gridFull.ma-0(v-else="!isLoading && dataSummary.length" :class="{'borderDelivery': typeOpinionSelected === 'delivery'}")
        .d-flex.headTitleGrid
            .block-title.titleGrid
                translate.mb-0.title-card Opinions
                span.ml-1.tDelivery(v-if="typeOpinionSelected === 'delivery'") Delivery
            .block-help.ml-auto
                v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="40" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                    template(v-slot:activator="{ on, attrs }")
                        img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                    span(v-if="!isAdverbia") {{helpNOp}}
                    span(v-else) {{ helpNOpAdverbia }}
                v-icon.mb-1(v-if="typeNode!=='group'" @click="goFeedback(typeOpinionSelected)") mdi-chevron-right

        .card-content
            .row.text-left
                #tableNumeroOpiniones.tableGlobal
                    v-row.headerTableRanking.row.ma-0.no-gutters
                        v-col.col-2.headerItem
                            p
                        v-col.headerItem.justify-center.text-center
                            p.mb-0 #[translate Total Opinions]
                        //TODO: add translations
                        v-col.headerItem.justify-center
                            p.mb-0 #[translate Opiniones / Local]
                        v-col.headerItem.justify-center
                            p.mb-0 #[translate Valoration]
                        v-col.col-2.headerItem.justify-center
                            p.mb-0 #[translate Feeling]
                        v-col.headerItem.justify-center
                            p.mb-0 #[translate Positives]
                        v-col.headerItem.justify-center
                            p.mb-0 #[translate Neutral]
                        v-col.headerItem.justify-center
                            p.mb-0 #[translate Negatives]
                        v-col.headerItem.justify-center
                            p.mb-0 #[translate Tend]
                    div.contentTableRanking.row.ma-0
                        p.text-center(v-if="!dataSummary") #[translate No data]
                        div.itemContent.col-12.pa-0(v-for="(item, index) in dataSummary" :key="index")
                            v-row.no-gutters.align-center
                                v-col.d-flex.justify-start.pl-4.col-2.item(:class="getTextBlackIfGroup(item)")
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            span.ellipsis1Col(v-if="index == 0" v-bind="attrs" v-on="on") {{item.name}}
                                            span.link-text.ellipsis1Col(v-if="index>0" @click="goItemNextLevel(item)" v-bind="attrs" v-on="on") {{item.name}}
                                        span(v-if="index == 0") {{item.name}}
                                        span(v-if="index>0" ) {{item.name}}
                                v-col.d-flex.justify-center
                                    div.item.text-right
                                        p.mb-0 {{ item.review_count | formatNumber }}
                                    div.item.pl-2
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.review_count,item.tend_review_count)")
                                            span {{ item.tend_review_count | formatNumber }}
                                v-col.d-flex.justify-center
                                    div.item.text-right
                                        p.mb-0(v-if="item.avg_review_count") {{ item.avg_review_count | formatNumber }}
                                        p.mb-0(v-else) {{ "-" }}
                                v-col.d-flex.justify-center
                                    div.item.text-right
                                        p.mb-0(:class="getColorByNoteNormalized({note: getNoteNormalized(item.review_rating_global)})") {{ getNoteNormalized(item.review_rating_global) | formatNumber(1,1) }}
                                    div.item.justify-center.pl-2
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.review_rating_global,item.tend_review_rating_global)")
                                            span {{ getNoteNormalized(item.tend_review_rating_global) | formatNumber }}
                                v-col.col-2
                                    p.mb-0
                                        chart-bar-three-colors(:slim="true" :percentGreen="item.review_percent_positive" :percentOrange="item.review_percent_neutral" :percentRed="item.review_percent_negative")

                                v-col.d-flex.justify-center.flex-col
                                    p.mb-0 {{ item.review_percent_positive | RoundedNumber }} %    
                                    p.numberOpinionSmall.mb-0 ({{ item.review_count_positive | formatNumber }})
                                v-col.d-flex.justify-center.flex-col
                                    p.mb-0 {{ item.review_percent_neutral | RoundedNumber }} % 
                                    p.numberOpinionSmall.mb-0 ({{ item.review_count_neutral | formatNumber }})
                                v-col.d-flex.justify-center.flex-col
                                    p.mb-0 {{ item.review_percent_negative | RoundedNumber }} % 
                                    p.numberOpinionSmall.mb-0 ({{ item.review_count_negative | formatNumber }})
                                v-col.d-flex.justify-center
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(item.review_percent_positive, item.tend_review_percent_positive)")
                                        span {{ item.tend_review_percent_positive | formatNumber }}

                        div.itemContent.col-12.pa-0(v-if="typeNode === 'center' && dataSummary.length > 0 && dataSummary[0].chain_data")
                            v-row.no-gutters.align-center
                                v-col.col-2.item.col-name.pl-4.media-cadena.text-start
                                    translate Chain mean
                                v-col.d-flex.justify-center
                                    div.item
                                        p.mb-0 {{ dataSummary[0].chain_data.review_count | formatNumber }}
                                    div.item.pl-2
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.arrow(v-bind="attrs" v-on="on" :class="getArrow(dataSummary[0].chain_data.review_count,dataSummary[0].chain_data.tend_review_count)")
                                            span {{ dataSummary[0].chain_data.tend_review_count | formatNumber }}
                                v-col.d-flex.justify-center
                                    p.mb-0 {{ dataSummary[0].chain_data.avg_review_count ? (dataSummary[0].chain_data.avg_review_count | formatNumber) : "-" }}
                                v-col.d-flex.justify-center
                                    div.item.text-right
                                        p.mb-0(:class="getColorByNoteNormalized({note: getNoteNormalized(dataSummary[0].chain_data.review_rating_global)})") {{ getNoteNormalized(dataSummary[0].chain_data.review_rating_global) | formatNumber(1,1) }}
                                    div.item.pl-2
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.arrow(v-bind="attrs" v-on="on" :class="getArrow(dataSummary[0].chain_data.review_rating_global,dataSummary[0].chain_data.tend_review_rating_global)")
                                            span {{ dataSummary[0].chain_data.tend_review_rating_global | formatNumber }}
                                v-col.col-2
                                    p.mb-0
                                        chart-bar-three-colors(:slim="true" :percentGreen="dataSummary[0].chain_data.review_percent_positive" :percentOrange="dataSummary[0].chain_data.review_percent_neutral" :percentRed="dataSummary[0].chain_data.review_percent_negative")
                                v-col.d-flex.justify-center.flex-col
                                    p.mb-0 {{ dataSummary[0].chain_data.review_percent_positive | RoundedNumber }} %    
                                    p.numberOpinionSmall.mb-0 ({{ dataSummary[0].chain_data.review_count_positive | formatNumber }})
                                v-col.d-flex.justify-center.flex-col
                                    p.mb-0 {{ dataSummary[0].chain_data.review_percent_neutral | RoundedNumber }} % 
                                    p.numberOpinionSmall.mb-0 ({{ dataSummary[0].chain_data.review_count_neutral | formatNumber }})
                                v-col.d-flex.justify-center.flex-col
                                    p.mb-0 {{ dataSummary[0].chain_data.review_percent_negative | RoundedNumber }} % 
                                    p.numberOpinionSmall.mb-0 ({{ dataSummary[0].chain_data.review_count_negative | formatNumber }})
                                v-col.d-flex.justify-center
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(dataSummary[0].chain_data.review_percent_positive, dataSummary[0].chain_data.tend_review_percent_positive)")
                                        span {{ dataSummary[0].chain_data.tend_review_percent_positive | formatNumber }}
</template>


<script>
import commonMixins from "@/mixins/common.js"
import SkeletonCard from '@/components/common/skeletonCard.vue'
import ChartBarThreeColors from '@/components/charts/BarTrheeColors.vue'

export default {
    props: {
        'dataSummary': [],
        'col': {
            type: String,
            required: false,
            default: '.col-3'
        }
    },
    mixins: [commonMixins],
    components: { SkeletonCard, ChartBarThreeColors },
    data() {
        return {
            helpNOp: this.$gettext('Depending on the selected period, it shows the total number of Opinions received and after analyzing the opinion notes the average of the Valuation versus its trend, comparing them with the same period of the previous year. It is analyzed about the group of restaurants that marks the selection in the left menu and the filters applied.'),
            helpNOpAdverbia: 'Según el periodo seleccionado, muestra el número total de Opiniones recibidas y tras analizar la opinión anota la media de la Valoración frente a su tendencia, comparándolas con el mismo periodo del año anterior. Se analiza sobre el grupo de negocios que marca la selección en el menú de la izquierda y los filtros aplicados.'

        }

    }
}
</script>

<style lang="scss" scoped>
#tableNumeroOpiniones {
    .col-name {
        text-align: left;

        &.textBlackGroup {
            color: #000;
        }

        &.media-cadena {
            color: #979797;

            span {
                letter-spacing: -0.02px;
            }
        }
    }

    .headerItem {
        &.col-vspa {
            max-width: 29%;
            flex: 0 0 29%;
        }

        &.col-numero {
            max-width: 24%;
            flex: 0 0 24%;
        }
    }

    .contentTableRanking {
        .col-numero {
            max-width: 14%;
            flex: 0 0 14%;
        }
    }

    .col-tend {
        max-width: 10%;
        flex: 0 0 10%;
        margin-right: 2%;
        text-align: right;
    }

    .col-vspa {
        max-width: 16%;
        flex: 0 0 16%;
    }
}
</style>