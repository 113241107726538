<template lang="pug">
div.pa-3.main
    div(ref="cardRef")
        v-card.px-6.py-10.__card(v-if="data.real_rating")
            v-row
                v-col.col-12
                    span {{ data.center_name }}
            v-row.d-flex.justify-space-around
                //Google rating card
                v-col.col-5.col-md-2.mb-2.mb-md-0
                    div.d-flex.flex-column.text-center
                        span.font-weight-bold.text-16 #[translate Google Ranking]
                        span.text-32 {{ data.historical_rating }}
                        div.d-flex.items-center.justify-center.py-2
                            google-rating(:nota="data.historical_rating" :height="20")
                            span.pl-1.text-14.font-weight-medium {{ data.n_reviews }} #[translate reseñas]

                        one-color-bar-with-text(v-for="item in data.n_stars_reviews" :key="item.id" :width="$options.filters.PercentFromValue(item, data.n_reviews)" :label="item.toString()")
                //Real rating card
                v-col.col-5.mb-2.ml-2.mb-md-0.col-md-3.ml-md-2.__card.--bg-gray(v-if="data.real_rating")
                    div.d-flex.flex-column.text-center.px-4.py-2
                        span.font-weight-bold.text-16 #[translate Rating real]
                        span.text-32.text-md-41.font-weight-medium {{ data.real_rating }}
                        span.text-center.text-md-left.text-13 #[translate Se necesitan]
                        div.d-flex.flex-column.flex-md-row.items-center.justify-center.pl-3
                            span.text-32.font-weight-bold {{ data.n_5_stars_for_next_near_decimal }}
                            span.text-12.text-left.pl-2 #[translate reseñas de 5 estrellas para lograr una calificación de] {{ data.next_google_rating }} #[translate estrellas].
                //Progress card
                v-col.col-12.col-md-3.ml-md-2.__card.--border-gray
                    div.d-flex.flex-column.text-center.px-4.py-6.__progress
                        span.font-weight-bold.text-16.pb-6 #[translate Progreso a] {{ data.next_google_rating }}

                        div.--container
                            div.py-8
                                one-color-bar(:width="data.percentage_of_progress" label="3122" :height="12")
                            span.text-18.__label.--top.--left {{ data.previous_google_rating }}
                            span.text-18.font-weight-bold.__label.--top.--center(v-if="data.percentage_of_progress") {{data.percentage_of_progress.toFixed(0)}}%
                            span.text-18.__label.--top.--right {{ data.next_google_rating }}
                            img.__label.--bottom.--left(:src="img.googleStart")
                            img.__label.--bottom.--right(:src="img.googleStart")

                        div
                            span.font-weight-bold.text-16.pb-15 #[translate Tendencia últimos 90 días]

                            div.d-flex.justify-space-around.px-4
                                span(v-if="data.last_real_rating") {{ data.last_real_rating.toFixed(2)}}
                                span(v-else) -
                                div.d-flex.items-center
                                    span.mb-0.ml-2.arrow(:class="getArrow(data.real_rating, data.last_real_rating)")
                                    span.text-18.font-weight-bold.pl-1(v-if="data.real_rating_tendency") {{ data.real_rating_tendency.toFixed(2) }}
                                span {{ data.real_rating }}

                //Trend card
                v-col.col-5.col-md-3.ml-md-2.__card.--border-gray.d-none.d-md-block
                    div.d-flex.flex-column.text-center.px-4.py-6.__progress
                        span.font-weight-bold.text-16.pb-10 #[translate Tendencia últimos 90 días]

                        div.d-flex.justify-space-between.px-4
                            span(v-if="data.last_real_rating") {{ data.last_real_rating.toFixed(2)}}
                            span(v-else) -
                            div.d-flex.items-center
                                span.mb-0.ml-2.arrow(:class="getArrow(data.real_rating, data.last_real_rating)")
                                span.text-18.font-weight-bold.pl-1(v-if="data.real_rating_tendency") {{ data.real_rating_tendency.toFixed(2) }}
                            span {{ data.real_rating }}

            v-row.pt-8.d.none.d-md-flex
                v-col.col-12
                    span.font-weight-bold.text-16.__title #[translate Evolución de la tendencia]
                v-col.col-12.pa-0
                    google-true-rating-chart(:chart-data="chartData")

            v-row.pt-8.d.flex.d-md-none
                v-col.col-12
                    span.font-weight-bold.text-16.__title #[translate Evolución de la tendencia]
                v-col.col-12.pa-0.d-flex.justify-center
                    mobile-true-rating-chart(:width="cardWidth-24" :leftIndicators="chartData.labels" :ratings="data.trends.real_ratings" :tendency="data.trends.tendency")

        warning-not-delivery-not-internet(v-else-if="!loading" type="global")
</template>

<script>
import OneColorBarWithText from '@/components/common/OneColorBarWithText.vue';
import googleRating from '@/components/common/googleRating.vue';
import OneColorBar from '@/components/charts/OneColorBar.vue';
import commonMixing from '@/mixins/common';
import WarningNotDeliveryNotInternet from '@/components/common/WarningNotDeliveryNotInternet.vue'
import GoogleTrueRatingChart from '../charts/GoogleTrueRating.vue';
import MobileTrueRatingChart from "../charts/MobileTrueRating"

export default {
    mixins: [commonMixing],
    components: { OneColorBarWithText, googleRating, OneColorBar, WarningNotDeliveryNotInternet, GoogleTrueRatingChart, MobileTrueRatingChart },
    props: {
        data: null,
        loading: {
            type: Boolean,
            require: true
        }
    },
    data() {
        return {
            img: {
                googleStart: require("@/assets/img/google_star.svg")
            },
            cardWidth: 0
        }
    },
    mounted() {
        this.setCardWidth()

        // window.addEventListener("resize", debounce(this.setCardWidth, 1000))
    },
    // beforeDestroy() {
    //     window.removeEventListener("resize", debounce(this.setCardWidth, 1000))
    // },
    methods: {
        setCardWidth() {
            this.cardWidth = this.$refs?.cardRef?.clientWidth ?? 0
        },
    },
    computed: {
        chartData() {
            const trends = this.data?.trends
            let data = null
            if (trends) {
                const months = trends.dates.map((item) => this.$moment(item).format("MMM YYYY"))
                data = {
                    labels: months,
                    datasets: [{
                        data: trends.real_ratings,
                        label: this.$gettext('Rating'),
                        yAxisID: "y-axis-1",
                        fill: false,
                        borderColor: '#9b9b9b',
                        borderWidth: 1,
                        pointBackgroundColor: "#9b9b9b",
                        pointBorderColor: "#9b9b9b",
                        pointRadius: 5,
                        datalabels: {
                            padding: 15,
                            align: 'bottom',
                            anchor: 'end',
                        },
                    }]
                }
            }
            return data
        },
    },
}
</script>
<style lang="scss" scoped>
.main {
    background: inherit;
    min-width: 100%;

    .__card {
        border-radius: 10px;

        .__title {
            position: relative;

            ::after {
                position: absolute;
                content: '';
                width: 60%;
                bottom: -5px;
                left: 0;
                border-bottom: 2px solid #BFBFBF;
            }
        }

        &.--bg-gray {
            background: #F1F3F4;
        }

        &.--border-gray {
            border: 2px solid #E2E2E2;
        }

        .__progress {
            .--container {
                position: relative;
                align-items: end;

                .__label {
                    position: absolute;

                    &.--top {
                        top: 0;
                    }

                    &.--bottom {
                        bottom: 0;
                    }


                    &.--left {
                        left: 0;
                    }

                    &.--center {
                        left: 0;
                        right: 0;
                        margin: auto;
                    }

                    &.--right {
                        right: 0;
                    }
                }
            }
        }
    }

}
</style>
        